<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { productTypeList } from '@/utils/textFile'

export default {
  name: 'coupons',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/base/farmCouponRule/list',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          key: 'couponName',
          type: 'input'
        },
        {
          name: '优惠方式',
          type: 'select',
          key: 'discountType',
          typeData: [
            {
              name: '立减',
              value: '1'
            },
            {
              name: '满减',
              value: '2'
            },
            {
              name: '折扣',
              value: '3'
            }
          ]
        },
        {
          name: '针对商家',
          type: 'select',
          key: 'shopType',
          typeData: [
            {
              name: '指定商家',
              value: '1'
            },
            {
              name: '全部商家',
              value: '0'
            }
          ]
        },
        {
          name: '产品类型',
          type: 'select',
          key: 'productType',
          typeData: productTypeList()
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'couponName',
          title: '名称',
          align: 'left',
          sorter: (a, b) => a.couponName.length - b.couponName.length
        },
        {
          dataIndex: 'discountType',
          title: '优惠方式',
          align: 'left',
          sorter: (a, b) => a.discountType - b.discountType,
          customRender: function(text, records) {
            return records.discountType == null ? '' : <div>{['', '立减', '满减', '折扣'][text]}</div>
          },
          onExport: records => {
            return ['', '立减', '满减', '折扣'][records]
          }
        },
        {
          dataIndex: 'couponType',
          title: '类型',
          align: 'left',
          customRender: function(text, records) {
            return records.couponType == null ? '' : <div>{text == '1' ? '数币券' : '优惠券'}</div>
          },
          onExport: records => {
            return records == '1' ? '数币券' : '优惠券'
          },
          filters: [
            {
              text: '数币券',
              value: '1'
            },
            {
              text: '优惠券',
              value: '0'
            }
          ],
          filterMultiple: false
        },
        {
          dataIndex: 'shopType',
          title: '针对商家',
          align: 'left',
          customRender: function(text, records) {
            return records.shopType == null ? '' : <div>{text == 1 ? '指定商家' : '全部商家'}</div>
          },
          onExport: records => {
            return records == 1 ? '指定商家' : '全部商家'
          }
        },
        {
          dataIndex: 'productType',
          title: '针对产品类型',
          type: 'tagGroup',
          align: 'left',
          getData: records => {
            if (records.productType.indexOf('0') > -1) {
              return ['全场']
            } else {
              return records.productType.split(',').map(e => {
                return {
                  '0': '全场',
                  '1': '商城',
                  '2': '农家乐',
                  '3': '民宿农舍',
                  '4': '农事体验',
                  '6': '扫码支付-支付宝微信',
                  '7': '扫码支付-农行掌银'
                }[e]
              })
            }
          },
          onExport: records => {
            let arr = records.split(',').map(e => {
              return {
                '0': '全场',
                '1': '商城',
                '2': '农家乐',
                '3': '民宿农舍',
                '4': '农事体验',
                '6': '扫码支付-支付宝微信',
                '7': '扫码支付-农行掌银'
              }[e]
            })
            return arr.toString()
          }
        },
        {
          dataIndex: 'receivedTotal',
          title: '领取量',
          align: 'left',
          customRender: function(text, records) {
            return (
              <div style="color:#093C3D;font-weight:500">{`${records.receivedTotal ? records.receivedTotal : 0}/${
                records.giveNum
              }（${records.giveNum != 0 ? ((records.receivedTotal / records.giveNum) * 100).toFixed(0) : '0'}%）`}</div>
            )
          }
        },
        {
          dataIndex: 'usedTotal',
          title: '使用量',
          align: 'left',
          customRender: function(text, records) {
            return (
              <div style="color:#093C3D;font-weight:500">{`${records.usedTotal ? records.usedTotal : 0}/${
                records.receivedTotal
              }（${
                records.receivedTotal != 0 ? ((records.usedTotal / records.receivedTotal) * 100).toFixed(0) : '0'
              }%）`}</div>
            )
          }
        },
        {
          dataIndex: 'publishStatus',
          title: '状态',
          type: 'badge',
          filters: [
            {
              text: '上架',
              value: '1'
            },
            {
              text: '下架',
              value: '0'
            }
          ],
          onExport: records => {
            return ['下架', '上架'][records]
          },
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 1 ? '上架' : '下架',
              color: data == 1 ? 'green' : 'red'
            }
          }
        },
        {
          dataIndex: 'a8',
          title: '操作',
          type: 'buttonGroup',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: '二维码',
                onClick: () => {
                  if (!records.qrCode) {
                    this.$message.warning('此条数据暂无二维码！')
                  } else {
                    const a = document.createElement('a')
                    a.href = records.qrCode
                    a.download = '二维码.png'
                    a.style.display = 'none'
                    a.target = '_blank'
                    document.body.appendChild(a)
                    a.click()
                    document.body.removeChild(a)
                  }
                }
              },
              {
                name: '领取情况',
                onClick: () => {
                  this.$router.push('/marketingCenter/couponsReceive?id=' + records.id)
                }
              },
              {
                name: records.publishStatus == 0 ? '上架' : '下架',
                onClick: () => {
                  api.command.edit.call(this, {
                    url: `/base/farmCouponRule/${records.publishStatus == 0 ? 'up' : 'down'}?ids=${records.id}`
                  })
                }
              },
              {
                name: '编辑',
                onClick: () => this.$router.push('/marketingCenter/couponsDetail?id=' + records.id)
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.delPut.call(this, {
                    url: `/base/farmCouponRule/del?ids=${records.id}`
                  })
                }
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/marketingCenter/couponsDetail')
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: data => {
                let idList = data.map(e => e.id)
                let ids = idList.toString()
                return api.command.delPut.call(this, {
                  url: '/base/farmCouponRule/del?ids=' + ids
                })
              }
            },
            {
              name: '批量上架',
              onClick: data => {
                let idList = data.map(e => e.id)
                let ids = idList.toString()
                return api.command.edit.call(this, {
                  url: '/base/farmCouponRule/up?ids=' + ids
                })
              }
            },
            {
              name: '批量下架',
              onClick: data => {
                let idList = data.map(e => e.id)
                let ids = idList.toString()
                return api.command.edit.call(this, {
                  url: '/base/farmCouponRule/down?ids=' + ids
                })
              }
            }
          ]
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
